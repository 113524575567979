body.special-report-page {
    display: grid;
    place-items: center;

    @media (max-width: 1200px) {
        display: block;
    }

    #main-content {
        max-width: 100%;
    }

    .main-content {
        &.special-report {
            padding: 0 56px;

            @media (max-width: 1000px) {
                padding: 0 32px;
            }

            @media (max-width: 600px) {
                padding: 0 24px;
            }

            .content-list-columns {
                padding: 0 0 80px;

                @media (max-width: 1100px) {
                    max-width: 89vw;
                }

                @media (max-width: 1000px) {
                    display: block;
                }

                h2 {
                    font-size: 32px;
                    line-height: 1.3;
                    font-weight: 700;
                    font-family: $proxima;
                    margin: 0;
                    @media (max-width: 600px) {
                        font-size: 26px;
                    }
                }

                .article-list-item {
                    display: grid;
                    grid-template-columns: 324px 1fr;
                    gap: 48px;

                    @media (max-width: 1200px) {
                        grid-template-columns: 230px 1fr;
                        gap: 32px;
                    }

                    @media (max-width: 600px) {
                        padding-bottom: 24px;
                        gap: 20px;
                        grid-template-columns: 1fr 126px;

                        .content-list-text {
                            order: 1;
                        }

                        .content-list-image {
                            order: 2;
                        }
                    }

                    img {
                        width: 100%;
                        height: auto;
                        max-width: 314px;
                        @media (max-width: 600px) {
                            object-position: center;
                            object-fit: cover;
                            width: 100%;
                            height: 123px;
                        }
                    }

                    p.section {
                        margin: 0;
                        font-family: $proxima;
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 1.3;
                    }

                    h3 {
                        margin: 0;
                        font-family: $minion-condensed;
                        font-size: 30px;
                        font-weight: 500;
                        line-height: 1.3;

                        @media (max-width: 400px) {
                            font-size: 20px;
                            line-height: 1.2;
                        }
                    }

                    p {
                        &.dek {
                            font-family: $minion;
                            font-size: 22px;
                            font-weight: 400;
                            line-height: 1.3;
                            margin: 15px 0;
                            @media (max-width: 400px) {
                                display: none;
                            }
                        }
                    }
                }
            }

            .advertisement.fullwidth {
                @media (max-width: 1100px) {
                    display: none;
                }
            }
        }
    }

    .two-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0;
        align-items: center;

        @media (max-width: 1200px) {
            grid-template-columns: 1fr;
        }

        .special-report-column {
            margin: 0 auto;
            text-align: center;
            align-items: center;

            @media (max-width: 1200px) {
                margin-top: 20px;
            }

            @media (max-width: 600px) {
                width: auto;
                padding: 0 20px;
                text-align: left;
            }

            img {
                width: 100%;
                height: auto;
                display: block;
            }

            h1 {
                margin: 30px auto;
                font-size: 55px;
                font-family: $minion-condensed;
                font-weight: normal;
                line-height: 1.1;

                @media (max-width: 600px) {
                    margin: 20px auto;
                    font-size: 40px;
                    line-height: 1.1;
                }
            }

            p.series-tag {
                display: none;
            }

            p.subtitle {
                font-size: 28px;

                @media (max-width: 600px) {
                    font-size: 24px;
                }
            }

            .special-report-text {
                padding: 0 56px;

                @media (max-width: 600px) {
                    padding: 0;
                }

                hr {
                    display: none;
                }

                .special-report-heading {
                    color: $black;
                    font-family: $proxima;
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 1;

                    @media (max-width: 600px) {
                        font-size: 16px;
                    }
                }

                h2.subtitle {
                    font-size: 28px;
                    font-weight: 400;
                    line-height: 1.3;

                    @media (max-width: 600px) {
                        font-size: 24px;
                    }
                }
            }

            .special-report-text-row {
                .sponsor-text {
                    color: $digital-grey;
                    font-family: $proxima;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.4;
                }

                img {
                    max-width: 217px;
                    margin-left: 16px;
                    display: inline-block;
                    vertical-align: middle;

                    @media (max-width: 600px) {
                        margin: 10px 0;
                        display: block;
                    }
                }
            }
        }
    }

    figcaption.caption {
        padding: 10px 20px;

        @media (max-width: 600px) {
            font-size: 16px;
        }
    }

    .special-report-featured-stories {
        max-width: 1400px;
        margin: auto;
        padding: 56px 56px 36px;

        @media (max-width: 600px) {
            padding: 50px 24px;
        }

        .article-list-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 48px;

            @media (max-width: 1000px) {
                display: block;
            }

            .article-list-item {
                @media (max-width: 1000px) {
                    margin-bottom: 40px;
                }
            }

            .article-list-image img {
                width: 100%;
                height: auto;
            }

            p.section {
                margin: 10px 0 5px;
                font-family: $proxima;
                font-size: 18px;
                font-weight: bold;
                line-height: 1.3;
            }

            h3 {
                margin: 0;
                font-size: 30px;
                font-weight: normal;
                line-height: 1.3;

                @media (max-width: 600px) {
                    font-size: 26px;
                }
            }

            p.special-summary {
                margin: 10px 0;
                font-size: 22px;
            }
        }
    }

    .special-report.article-list {
        width: auto;
        max-width: 1400px;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 300px;
        gap: 55px;

        @media (max-width: 1000px) {
            display: block;
        }

        @media (max-width: 600px) {
            padding: 0 24px;
        }

        .article-list-item {
            margin: 40px 0;
            display: grid;
            grid-template-columns: 314px 1fr;
            gap: 48px;

            @media (max-width: 600px) {
                margin: 32px 0;
                grid-template-columns: 126px 1fr;
                gap: 16px;
            }

            img {
                width: 100%;
                height: auto;
            }

            .article-list-text {
                p.section {
                    margin: 0;
                    font-family: $proxima;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1.3;

                    @media (max-width: 600px) {
                        font-size: 16px;
                    }
                }

                h3 {
                    margin: 5px 0;
                    font-size: 30px;
                    font-weight: normal;

                    @media (max-width: 600px) {
                        font-size: 20px;
                        line-height: 1.4;
                    }
                }

                p.article-summary {
                    margin: 0;
                    font-size: 22px;

                    @media (max-width: 1000px) {
                        display: none;
                    }
                }
            }
        }
    }
}

.special-title {
    a {
        font-size: 30px !important;
    }
}
