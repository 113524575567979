body.article-template {
    &.longform {
        display: grid;
        place-items: center;

        @media (max-width: 1200px) {
            display: block;
        }

        #main-content {
            max-width: 100%;
        }

        .lead-article-image {
            display: none;
        }

        .longform-header-container {
            .longform-full-width {
                .full-width-container {
                    position: relative;
                    display: block;

                    .full-width-gradient {
                        .full-width-content {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            z-index: 5;
                            background: rgb(255, 255, 255);
                            background: linear-gradient(
                                180deg,
                                rgba(255, 255, 255, 0) 0%,
                                rgba(0, 0, 0, 1) 75%
                            );
                            padding: 150px 50px 50px;

                            @media (max-width: 1000px) {
                                padding: 0 20px;
                                background: none;
                                position: relative;
                            }

                            h1 {
                                font-size: 2.813rem;
                                font-weight: 400;
                                line-height: 2.813rem;
                                margin: 0;
                                padding: 0;
                                color: $white;
                            }

                            p.series-tag {
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                font-size: 1.15rem;
                                color: $white;

                                a {
                                    font-weight: 700;
                                    color: $white;
                                    @media (max-width: 1000px) {
                                        color: #000;
                                    }
                                }

                                .pub-date {
                                    color: $white;
                                }
                            }

                            p.subtitle {
                                margin: 21px 0 17px 0;
                                padding: 0;
                                font-family: $minion-condensed;
                                color: $white;
                                font-size: 1.4rem;
                                font-weight: 400;
                                line-height: 1.3;
                            }

                            .article-special-logo {
                                p {
                                    margin-right: 15px;
                                    color: $white;
                                    font-family: $proxima;
                                    font-size: 14px;
                                    display: inline-block;
                                    vertical-align: middle;
                                }

                                img {
                                    max-width: 217px;
                                    height: auto;
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                            }
                        }

                        .mobile-heading {
                            display: none;
                        }

                        .desktop-heading {
                            display: block;
                            position: relative;

                            img {
                                width: 100%;
                                height: auto;
                                display: block;
                            }
                        }
                    }
                }
            }

            .longform-columns {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 0;
                align-items: center;

                @media (max-width: 1000px) {
                    grid-template-columns: 1fr;
                }

                .longform-column {
                    .article-special-logo {
                        @media (max-width: 1000px) {
                            margin-bottom: 20px;
                        }
                    }

                    .mobile-heading {
                        display: none;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .desktop-heading {
                        display: block;

                        img {
                            width: 100%;
                            height: auto;
                            display: block;
                        }
                    }

                    &.with-text {
                        width: 75%;
                        margin: 20px auto 0;
                        text-align: center;
                        align-items: center;

                        @media (max-width: 600px) {
                            width: auto;
                            padding: 20px 20px 0;
                            text-align: left;
                        }

                        h1 {
                            font-size: 55px;
                            font-family: $minion-condensed;
                            font-weight: normal;
                            line-height: 1.1;

                            @media (max-width: 600px) {
                                // font-size: 40px;
                                font-size: 32px;
                                line-height: 1.1;
                            }
                        }

                        p.series-tag {
                            display: none;
                        }

                        p.subtitle {
                            font-size: 28px;
                            font-family: $minion-condensed;

                            @media (max-width: 600px) {
                                font-size: 24px;
                            }
                        }

                        .article-special-logo {
                            p {
                                margin-right: 15px;
                                color: $digital-grey;
                                font-family: $proxima;
                                font-size: 14px;
                                display: inline-block;
                                vertical-align: middle;
                            }

                            img {
                                max-width: 217px;
                                height: auto;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                    }

                    &.hero-image {
                        align-self: start;
                    }
                }

                figcaption.caption {
                    padding: 12px 12px 20px 0;
                    @media (max-width: 1000px) {
                        padding: 20px 30px;
                    }
                }
            }
        }

        article.main-content.article.longform-article {
            @media (max-width: 1000px) {
                margin-top: 10px;
            }

            header.article-header {
                margin-bottom: 80px;

                @media (max-width: 600px) {
                    font-size: 12px;
                }
            }
        }
    }

    article.main-content.article.longform-article {
        header.article-header {
            margin-bottom: 80px;

            @media (max-width: 600px) {
                margin-bottom: 25px;
            }

            .series-tag,
            h1,
            h2 {
                display: none;
            }

            .article-line {
                p.author,
                p.photographer,
                time {
                    margin: 0;
                    padding: 0;
                    color: $black;
                    font-family: $proxima;
                    font-size: 18px;
                    line-height: 1.3;

                    @media (max-width: 600px) {
                        font-size: 16px;
                        line-height: 1.1;
                    }

                    a {
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                time {
                    color: $digital-grey;
                    font-weight: lighter;

                    @media (max-width: 600px) {
                        margin: 10px 0 25px;
                        display: block;
                    }
                }
            }

            .sm-newsletter {
                max-width: 200px;
                height: 48px;
                padding: 1px 24px;
                background-color: #eee;
                text-align: center;
                display: flex;
                align-items: center;
                gap: 16px;

                @media (max-width: 600px) {
                    max-width: none;
                    padding: 1px 16px;
                    justify-content: center;
                }

                @media (max-width: 400px) {
                    margin-top: 20px;
                }

                i {
                    font-size: 22px;

                    @media (max-width: 600px) {
                        font-size: 20px;
                    }
                }

                .newsletter-text {
                    color: $black;
                    font-family: $proxima;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1.3;

                    @media (max-width: 600px) {
                        font-size: 18px;
                    }
                }
            }
        }

        .article-columns {
            @media (max-width: 1000px) {
                display: block;

                .articleRight {
                    margin-bottom: 25px;
                }
            }
        }
    }

    &.sponsored {
        .sponsored-heading {
            background: #f4f4f4;
            text-align: center;
            padding: 20px 0;

            @media (max-width: 1200px) {
                margin-bottom: 20px;
            }

            .sponsored-text {
                text-transform: uppercase;
                letter-spacing: 1px;
                color: $black;
                font-family: $proxima;
                font-weight: 600;
                font-size: 0.9rem;
            }
            .sponsored-logo {
                text-align: center;
                margin-top: 10px;
                img {
                    height: 75px;
                    width: auto;
                    @media (max-width: 600px) {
                        max-width: 90%;
                        height: auto;
                    }
                }
            }
        }

        .sponsored-notice {
            margin-top: 3.25rem;
            padding: 0 20px;
            background: #f4f4f4;
            text-align: center;

            button {
                width: 11rem;
                padding: 0.5rem 0;
                margin-left: 15px;
                border: 2px solid $black;
                color: $black;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 1px;
                position: relative;
                top: -4px;

                @media (max-width: 500px) {
                    margin: 20px auto 0;
                    display: block;
                }

                &:hover {
                    background: $black;
                }

                a {
                    display: block;
                    &:hover {
                        color: $white;
                        text-decoration: none;
                    }
                }
            }

            p {
                margin: 0;

                font-size: 1.625rem;
                font-weight: 700;
                padding: 70px 0;

                &.editorial {
                    padding: 20px 0;
                    border-top: 1px solid #d9d9d9;
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
        }

        .article-fullwidth-column {
            @media (max-width: 1000px) {
                padding: 0 56px;
            }

            @media (max-width: 800px) {
                padding: 0 32px;
            }

            @media (max-width: 600px) {
                padding: 0 26px;
            }

            figure.article-image {
                @media (max-width: 1000px) {
                    padding: 0;
                }
            }

            h2 {
                @media (max-width: 600px) {
                    font-size: 30px;
                }
            }
        }

        footer {
            margin-top: 0;
        }
    }
}
